.minimal-card {
    background-color: #f8f9fa;
    border: none;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.small-label {
    font-size: 0.85rem;
    color: #6c757d;
    display: block; /* Ensure the label is treated as a block element */
    margin-bottom: 0.25rem; /* Add some space between the label and the input */
}
.small-input {
    font-size: 1rem;
    font-weight: bold;
    padding: 0;
    border: none;
    background: transparent;
}

.minimal-card .card-body {
    padding: 10px;
}

.text-center {
    font-size: 1.1rem;
    font-weight: bold;
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.custom-label {
    font-size: 0.85rem;
    color: #6c757d;
    font-weight: 600; /* Optional: to make it stand out */
}